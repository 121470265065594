
import { defineComponent } from "vue";
import MainHeader from "@/components/MainHeader.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import CarouselPreview from "@/components/carousel/CarouselPreview.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    CarouselPreview,
    Carousel,
    MainHeader,
  },
  data() {
    return {
      selectedIndex: 2,
    };
  },
  methods: {
    setSelectedIndex(index: number) {
      this.selectedIndex = index;
    },
  },
});
