
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
    },
    isLogo: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
});
