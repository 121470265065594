

export default {
  props: {
    selectedIndex: {
      type: Number,
      default: 0,
    },
    indicatorCount: {
      type: Number,
      default: 0,
    },
    setPrevIndex: {
      type: Function,
      default: () => {
      },
    },
    setNextIndex: {
      type: Function,
      default: () => {
      },
    },
  },
}
