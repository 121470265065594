import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fe5ce1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "carousel-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_CarouselPreview = _resolveComponent("CarouselPreview")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainHeader, {
      title: "LOGO",
      "is-logo": "true"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CarouselPreview, { selectedIndex: _ctx.selectedIndex }, null, 8, ["selectedIndex"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Carousel, {
          "selected-index": _ctx.selectedIndex,
          "set-selected-index": _ctx.setSelectedIndex
        }, null, 8, ["selected-index", "set-selected-index"])
      ])
    ])
  ], 64))
}