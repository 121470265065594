<template>
    <ul>
        <slot/>
    </ul>
</template>

<style scoped lang="scss">

ul {

}

</style>
